import Amazon from "./Amazon.png";
import AppleTV from "./Apple TV.png";
import Disney from "./Disney White.webp";
import Netflix from "./Netflix.png";

const logo = {
  1024: Amazon,
  213: Netflix,
  2739: Disney,
  2552: AppleTV,
};

export default logo;
